import React from "react";
import './Footer.scss';
import { useState, useEffect } from "react";

function Footer(){
    // add timstamp on the footer
    const [year, setYear] = useState(new Date().getFullYear());
    useEffect (()=>{
        const interval = setInterval(()=>{
            setYear(new Date().getFullYear());
        }, 1000 * 60 * 60 * 24);
        return () => clearInterval(interval);
    }, []);

    return(
        <section className="footer">
            <p className="footer__text">© {year} Portfolio by Sony Chen. All rights reserved.</p>
        </section>

    );
}

export default Footer;